import React from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  Link,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import Logo from "../../assets/images/steev-logo.png";
import Google from "../../assets/images/Google.png";
import AppStore from "../../assets/images/App-store.png";
import { SOCIAL_LINKS } from "../../config";
import { useStyles } from "./style";
import { constants } from "../../constants/nofound";

export default function Index() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{constants.STEEV_HOME_TITLE}</title>
        <meta
          name="description"
          content="People come to Steev to listen and participate in thought provoking, inspirational and funny conversations with amazing people."
        />
      </Helmet>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className={classes.mediapadding} textAlign={"center"} pt={22}>
              <img
                src={Logo}
                className={classes.medialogo}
                alt="logo"
                height="auto"
                width="170px"
              />
              <Box pt={2} />
              <Typography className={classes.heading}>
                {constants.PAGE_NOT_FOUND}
              </Typography>
              <Box pt={2} />

              <Typography className={classes.text}>
                {constants.NOT_FOUND_PAGE}
              </Typography>
            </Box>


          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              item
              textAlign={"center"}
              my={20}
              style={{
                minWidth: 300,
              }}
              className="side-left-2"
            >
              <Typography>
                <Box
                  textAlign="center"
                  pt={1}
                  fontFamily="Helvetica"
                  fontWeight="700"
                  lineHeight="35px"
                  fontSize="28px"
                  color="#1C1C1E"
                >
                  {constants.GET_APP}
                </Box>
              </Typography>

              <Box pt={2} display="block" mt={1} justifyContent="center">
                <Link
                  href={SOCIAL_LINKS.GOOGLE_PLAY_LINK}
                  target="_blank"
                >
                  <img
                    className={classes.mediaImgfirst}
                    src={Google}
                    alt="Google"
                    height="auto"
                    width="200px"
                  />
                </Link>
                <Link
                  href={SOCIAL_LINKS.APPLE_LINK}
                  target="_blank"
                >
                  <img
                    style={{ marginTop: 0 }}
                    className={classes.mediaImg}
                    src={AppStore}
                    alt="AppStore"
                    height="auto"
                    width="200px"
                  />
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div >
  );
}
