import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "36px",
    paddingRight: 30,
    color: "#1C1C1E",
    fontFamily: "Helvetica",
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4F4F4F",
    fontFamily: "Helvetica",
  },
  link: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1C1C1E",
    fontFamily: "Helvetica",
  },
  icon: {
    position: "relative",
    top: "7.5px",
    left: "-2px",
  },
  mediaImg: {
    [theme.breakpoints.down("xs")]: {
      width: "157px",
    },
  },
  mediaImgfirst: {
    marginRight: 16,
    [theme.breakpoints.down("xs")]: {
      width: "170px",
    },
  },
  mediapadding: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
  },
  mediamobile: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  medialogo: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  imgSpace: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
}));
