import React, { useCallback, useEffect, useState } from "react";
import 'date-fns';
import PropTypes from 'prop-types';
import { Grid, Box, Avatar, Typography, Container } from "@material-ui/core/";
import axios from "axios";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Logo from "../../assets/images/steev-logo.png";
import {API} from "../../config/index"
import { BootstrapInput, useStyles } from "./style";
import { ALERT_MESSAGE, constants } from "../../constants/host";

export default function Index({ match }) {
  const classes = useStyles();
  const [state, setState] = useState({
    loading: false,
    data: null,
    tagsArr:false,
    savedTags:[],
    errors:[]
  });
  let typeArr = [
    {
      label:"MP3",
      name:"mp3"
    },
    {
      label:"MP4",
      name:"mp4"
    },
    {
      label:"Youtube",
      name:"youtube"
    },
  ]
  const [linkType, setLinkType] = useState("mp3");
  const handleLinkType=(val)=>{
    
    setLinkType(val)
  }
  
  const handleTags =(val)=>{
        if(state.savedTags.indexOf(val) !==-1){
          let tags=  state.savedTags.filter((item)=>item!==val);
          setState({ ...state, savedTags: tags, loading: false })
        }else{
          let tagsv =  state.savedTags;
          if(tagsv.length<3){
            tagsv.push(val)
            setState({ ...state, savedTags: tagsv, loading: false })
          }
          else{
            alert(ALERT_MESSAGE.TAGS_ONLY)
          }
         
         
        }
  }
  const getInterest = useCallback(async () =>{
    await axios
      .get(`${API}/get-interest`)
      .then((res) => {
        if(res.data.code === 200){
          setState((prevState) => ({
            ...prevState,
            tagsArr: res.data.data[0],
            loading: false,
          }));
        }
      });
  }, []);

  const handleSave = async () =>{
      const link= document.getElementById("link").value;
   
      const expireDate= document.getElementById("expireDate").value;
      const description= document.getElementById("desc").value;
      const title= document.getElementById("title").value;
      const sponserLink= document.getElementById("sponserName").value;
      const sponserImg= document.getElementById("sponserLogo").value;
      let arr =[];
      if(title === ""){
        arr.push({
            name:"title",
            value:"Title is required"
        })
      }
      if(description === ""){
        arr.push({
            name:"desc",
            value:"Description is required"
        })
      }
      if(expireDate === ""){
          arr.push({
              name:"expireDate",
              value:"Expire Date is required"
          })
      }
      if(linkType === ""){
          arr.push({
              name:"linkType",
              value:"Link Type is required"
          })
      }
      if(link === ""){
          arr.push({
              name:"link",
              value:"Link is required"
          })
      }

      if(state.savedTags.length === 0){

            arr.push({
                name:"tags",
                value:"Tags is required"
            })
      }

      if(arr.length === 0)
      {
            await axios
                  .post(`${API}/save-host`,{
                    title,
                    linkType,
                    description,
                    expireDate,
                    link,
                    sponserLink,
                    sponserImg,
                    tags:state.savedTags,

                  })
                  .then((res) => {
                      alert(ALERT_MESSAGE.ADD_HOST)
                      window.location.reload();
                    // setState({ ...state, tagsArr: [], loading: false })
                  });
      }
      else{
        setState({ ...state, errors: arr, loading: false })
      }
      

  }
  useEffect(() => {
    getInterest();
  }, [getInterest]);

  const displayError = (type)=>{
    let filter = state.errors.filter((item)=> type === item.name);
    if(filter.length>0){
      return (
        <span className={classes.err}>{filter[0].value}</span>
      )
    }
    else{
      return (
        <span  ></span>
      )
    }
   
  }

 

  return (
    <div className={classes.root}>
       
      {state.loading ? (
        <Box
          height="100vh"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Logo} alt="logo" height="auto" width="170.81px" />
        </Box>
      ) : (
        <Grid container justifyContent="center">
          <Grid item md={5} >
            <Container fixed>
              <Box py={5} textAlign="center">
                <img src={Logo} alt="logo" height="auto" width="170.81px" />
              </Box>
              <Box className={classes.backgroundcolor}>
                  <Typography className={classes.texttitle} component={"h5"} variant="h5">
                    {constants.MAIN_TITLE}
                  </Typography>
                  <FormControl className={classes.margin}>
                      <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                          {constants.TITLE} 
                      </InputLabel>
                    <BootstrapInput defaultValue="" id="title" />
                    {displayError('title')}
                  </FormControl>
                    <FormControl className={classes.margin}>
                      <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                          {constants.DESCRIPTION}
                      </InputLabel>
                    <BootstrapInput defaultValue="" id="desc" />
                    {displayError('desc')}
                  </FormControl>
                  <FormControl className={classes.margin}>
                      <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                          {constants.EXPIRE_DATE}
                      </InputLabel>
                    
                      <TextField
                          id="expireDate"
                          type="date"
                          style={{width:'94%'}}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                          {displayError('expireDate')}
                  </FormControl>
                  <FormControl className={classes.margin}>
                      <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                        {constants.HOST}
                      </InputLabel>
                      <div style={{display:'flex',marginTop:30}}>
                          <Avatar alt="Remy Sharp" src="/steev-logo.png" />
                          <Typography variant="button" className={classes.user} display="block" gutterBottom>
                            {constants.STEEV}
                          </Typography>
                        </div>
                      
                  </FormControl>
                
                  {state.tagsArr &&
                  <div style={{ marginTop: 20 }}>
                  {[
                    { label: constants.LANGUAGES, items: state.tagsArr.languages },
                    { label: constants.REGIONS, items: state.tagsArr.regions },
                    { label: constants.TOPIC, items: state.tagsArr.topics },
                  ]?.map(({ label, items }, index) => (
                    <div key={`${label}-${index}`}>
                      <InputLabel className={classes.label} shrink htmlFor="bootstrap-input">
                        {label}
                      </InputLabel>
                      <div className={classes.maintag}>
                        {items.map((item) => (
                          <div key={item.name}>
                            {state.savedTags.indexOf(item.name) !== -1 ? (
                              <Chip
                                label={item.name}
                                clickable
                                className={classes.chip}
                                color="secondary"
                                onClick={(e) => handleTags(item.name)}
                                onDelete={(e) => handleTags(item.name)}
                                deleteIcon={<HighlightOffIcon />}
                              />
                            ) : (
                              <Chip
                                label={item.name}
                                clickable
                                className={classes.chip}
                                color="secondary"
                                onClick={(e) => handleTags(item.name)}
                                deleteIcon={<></>}
                                onDelete={(e) => handleTags(item.name)}
                                variant="outlined"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                  }
                  {displayError('tags')}

                  <FormControl className={classes.margin}>
                      <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                        {constants.LINK_TYPE}  
                      </InputLabel>
                      <Select defaultValue={linkType} value={linkType} onChange={e=>handleLinkType(e.target.value)} id="linkType"   className={[classes.textField,classes.mt]}>
                        {typeArr.map((item)=>{
                          return (
                            <MenuItem value={item.name} key={item.name}>{item.label}</MenuItem>
                          ) 
                        })}
                      </Select>
                      {displayError('linkType')}
                  </FormControl>
                  <FormControl className={classes.margin}>
                      <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                          {constants.LINK}
                      </InputLabel>
                    <BootstrapInput defaultValue="" id="link" />
                    {displayError('link')}
                  </FormControl>
                  <FormControl className={classes.margin}>
                      <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                        {constants.BROUGHT_BY_WEB}
                      </InputLabel>
                    <BootstrapInput defaultValue="" id="sponserName" />
                  
                  </FormControl>
                  <FormControl className={classes.margin}>
                      <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                      {constants.BROUGHT_BY_LOGO}
                      </InputLabel>
                    <BootstrapInput defaultValue="" id="sponserLogo" />
                    
                  </FormControl>
                      <Button onClick={e=>handleSave()} className={classes.submitButtton} variant="contained"
                        color="secondary" disableElevation> <ScheduleIcon style={{ fontSize: 22 }} 
                        className={classes.icon} /> {constants.SCHEDULE} </Button>
              </Box>

            
            </Container>
          </Grid>
        </Grid>
      )}
    
      <Box py={10} />
      
    </div>
  );
}

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};