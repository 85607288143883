import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import theme from "./components/theme";
import { HomePage, ProfilePage, EventPage, HostAddPage, NotFoundRoutePage } from "../src/views";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/:id" component={ProfilePage} /> 
          <Route exact path="/event/:id" component={EventPage} />
          <Route exact path="/stream/:id" component={EventPage} />
          <Route exact path="/host/add/KeNIhHQTHHw" component={HostAddPage} />
          <Route exact path="/event/:id/:type" component={EventPage} />
          <Route exact path="/stream/:id/:type" component={EventPage} />
          <Route exact path="/links/event/:id/:type" component={EventPage} /> 
          <Route exact path="/links/events/:id/:type" component={EventPage} />
          <Route path="/not-found" component={NotFoundRoutePage} /> 
          <Route path="*" component={NotFoundRoutePage} /> 
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
