import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Grid, Box, Avatar, Typography, Container } from "@material-ui/core/";
import axios from "axios";
import moment from "moment-timezone";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PageNotFound from '../notFound';
import Logo from "../../assets/images/steev-logo.png";
import {API, STEEV_URL} from "../../config";
import Mysvg from "../../assets/share.svg";
import Mic from "../../assets/Mic.png";
import MicOptions from "../../assets/headset.svg";
import { useStyles } from "./style";
import { SimpleDialog } from '../../Modals'

function Index({ match }) {

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    data: null,
    events: []
  });

  const handleClickOpen = (val) => {
    setOpen(val);

  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  function delay() {
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }

  useEffect(() => {
    // Declare an async function inside useEffect
    const fetchData = async () => {
      try {
        // Fetch the user profile data using the ID from the URL parameter
        const res = await axios.get(`${API}/user/profile/${match?.params?.id}`);
        // Check if the response data contains the profile data
        if (res.data.data) {
          setState((prevState) => ({ ...prevState, data: res.data.data }));
          
          // Fetch the events for the user
          const eventResponse = await axios.get(
            `${API}/users/events/${res.data.data.profile._id}`
          );
          
          if (eventResponse.data.code === 200) {
            setState((prevState) => ({
              ...prevState,
              events: eventResponse.data.data,
            }));
          } else if (eventResponse.data.code === 400) {
            console.log('Event data not found');
            // Optionally, you could redirect here
          } else {
            console.log('Error fetching events:', eventResponse.data.code);
          }
          delay();
        } else {
          console.log('Missing Record');
        }
      } catch (error) {
        delay();
        console.log('Profile not found', error);
      }
    };

    // Call the async function
    fetchData();
  }, [match?.params?.id]);

  const mytimeZone = (item) => {
    let tzone = moment.tz.guess();
    let dateSplit = item.scheduleDate.split("T");
    const ac = moment(dateSplit[0] + item.scheduleTime).format(
      "YYYY-MM-DD HH:mm"
    );
    const tzConv = moment.tz(ac, item.timeZone);
    const convertedDt = tzConv
      .tz(
        typeof tzone === "number"
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : tzone
      )
      .format("MMM D YYYY  hh:mm A zz"); // mytimeZone  is my zone
    return convertedDt;
  };



  let hostName = "steev";
  let avtarName = "";
  if (loading === false && state.data) {
    if (state.data.profile) {
      hostName = state.data.profile.name;
      const spt = hostName.split(" ");
      spt.forEach((item) => {
        if (item !== "" && item !== null && item !== undefined && avtarName.length < 2) {
          if (avtarName === "") {
            avtarName = item.charAt(0);
          }
          else {
            avtarName += item.charAt(0);
          }
        }
      })
    }
  }

  if(loading===false && state.data === null){
    return <PageNotFound />
  }
  return (
    <div className={classes.root}>
      {loading ? (
        <Box
          height="100vh"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Logo} alt="logo" height="auto" width="170.81px" />
        </Box>
      ) : (
        <Grid container justifyContent="center">
          <Grid item md={9} style={{ padding: 42 }}>
            <Container fixed>
              <Box py={5} textAlign="center">
                <img src={Logo} alt="steev" height="auto" width="170.81px" />
              </Box>
              <Box className={classes.backgroundcolor}>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={8}>
                    <Grid container spacing={8}>
                      <Grid item lg={9} xs={12}>
                        <Box display="flex" alignItems="center">
                          {state.data.profile.profilePic !== undefined ? (

                            <Avatar
                              alt={state.data.profile.name}
                              src={state.data.profile.profilePic}
                              className={classes.large}></Avatar>


                          ) : (
                            <Avatar
                              alt={state.data.profile.name}
                              style={{ background: "gray" }}
                              src={state.data.profile.profilePic}
                              className={classes.large}
                            > {avtarName} </Avatar>
                          )}

                          <Box ml={2}>
                            <Typography className={classes.username}>
                              {state.data.profile.name}
                            </Typography>
                            <Typography className={classes.usermail}>
                              @{state.data.profile.username}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} xs={5}>
                    <Grid container spacing={2} style={{ marginTop: 0 }}>
                      <Grid item xs={4} >
                        <Box className={classes.floatright} pt={1}>
                          <Typography className={classes.followingnumbr}>
                            {state.data.followingCount}
                          </Typography>
                          
                            <Typography className={classes.usermail}>
                              {state.data.followingCount > 1 ? 'Followings' : 'Following'}
                          </Typography> 
                          
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box pt={1} className={classes.floatright}>
                          <Typography className={classes.followingnumbr}>
                            {state.data.followersCount}
                          </Typography>
                          <Typography className={classes.usermail}>
                            {state.data.followersCount > 1 ? 'Followers':   'Follower'}
                          </Typography> 
                          
                        
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box pt={1} className={classes.floatright}>
                          <Typography className={classes.followingnumbr}>
                            {state.events.length}
                          </Typography>

                          <Typography className={classes.usermail}>
                            {state.events.length > 1 ? 'Streams':'Stream' }
                          </Typography>
                          
                          

                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography className={classes.texttitle}>about</Typography>
                <Typography component="div">
                  <Box
                    py={2}
                    fontFamily="Helvetica"
                    lineHeight="22px"
                    fontSize="16px"
                    color="#1C1C1E"
                    dangerouslySetInnerHTML={{
                      __html: state.data.profile.bio !== undefined ? state.data.profile.bio : '...',
                    }}
                  />
                </Typography>
              </Box>

              {open &&
                <SimpleDialog
                  selectedValue={selectedValue}
                  open={open}
                  onClose={handleClose}
                />
              }

              {state.events.length > 0 ? <Typography className={classes.usermail}>
                <span>Streams</span>
              </Typography> : <Typography className={classes.usermail}>
                <span>Stream</span>
              </Typography>}
              <Grid container justifyContent="space-between">
                {state.events.length > 0 && state.events.map((item) => {
                  const urlRedirect = `${STEEV_URL}/event/${item._id}/online`
                  return (
                    <Grid item xs={4} key={item._id}>
                      <Box my={3} className={classes.Striminglog} sx={{ m: 1, mb: 0 }}>
                        <Grid container justifyContent="space-between" >
                          <Typography className={classes.backgroundColorstreamdateTime}>
                            <AccessTimeIcon className={classes.clockiconcolor} />
                            {mytimeZone(item)}</Typography>
                          <Typography onClick={() => handleClickOpen(item)}><img src={Mysvg} className={classes.shareIcon} alt="share-img"></img></Typography>
                        </Grid>
                        <Grid container style={{ marginTop: 10 }}>
                          {item?.tags[1] && <Typography className={classes.country}>{item.tags[1]}</Typography> }
                          {item?.tags[0] && <Typography className={classes.language}>{item.tags[0]}</Typography> }
                          {item?.tags[2] && <Typography className={classes.startup}>{item.tags[2]}</Typography> }
                        </Grid>
                        <Typography style={{ marginTop: 10 }}
                          className={classes.quation}><a href={urlRedirect}
                            className={classes.truncatedText}
                            style={{ color: "black", textDecoration: "none" }}>{item.title}</a></Typography>
                        <Grid style={{ marginTop: 10 }}>
                          <Typography className={classes.streamers}><img src={Mic} alt="mic-img" ></img><span style={{ color: "black" }}>{item.userId.name}</span>  </Typography>
                        </Grid>
                        <Typography style={{ marginTop: 10 }} className={classes.streamers}><img src={MicOptions} alt="headphone-icon" style={{ color: "black", width: "13px", marginRight: "5px", marginLeft: 3 }} />{item.viewers.length}</Typography>
                      </Box>
                    </Grid>
                  )
                })}
                {state.events.length === 0 &&
                  <Grid container justifyContent="center" >
                    <Typography style={{ marginTop: 10, fontSize: "30px", fontWeight: "500" }}  >
                      {'No Streams'}</Typography>
                  </Grid>
                }
              </Grid>
            </Container>
          </Grid>
        </Grid>
      )}
      <Box py={10} />
    </div>
  );
}

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default Index;