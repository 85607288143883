export const constants = {
  MAIN_TITLE: "Host your show",
  TITLE: "Title",
  DESCRIPTION: "Description",
  EXPIRE_DATE: "Expiration Date",
  HOST: "Host",
  STEEV: "Steev",
  LANGUAGES: "Languages",
  REGIONS: "Regions",
  TOPIC: "Topic",
  LINK_TYPE: "Link Type",
  LINK: "Link",
  BROUGHT_BY_WEB: "Brought By (Website)",
  BROUGHT_BY_LOGO: "Brought By (Logo)",
  SCHEDULE: "Schedule",
};

export const ALERT_MESSAGE = {
  TAGS_ONLY: "You can select maximum 3 tags only",
  ADD_HOST: "Success ! Host has been added successfully",
};
