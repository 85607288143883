import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
  },
  backgroundcolor: {
    backgroundColor: "white",
    borderRadius: "16px",
    boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.1)",
    padding: "16px",
    minHeight: "auto",
    marginBottom: 50,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  Dialog: {
    "& .MuiPaper-root": {
      width: 320,
    },
  },
  social: {
    width: "100%",
  },
  username: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  usermail: {
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: 400,
    color: " rgba(28, 28, 30, 0.6)",
    fontFamily: "Helvetica",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  followingnumbr: {
    fontSize: "18px",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
    marginRight: "5px",
  },
  followingtext: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    color: " rgba(28, 28, 30, 0.6)",
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  floatright: {
    float: "left",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      float: "none",
    },
  },

  texttitle: {
    color: "#8A8FA9",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    fontSize: "10px",
    marginTop: 20,
    textTransform: "uppercase",
  },
  backgroundcolortitle: {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "30px",
  },

  Striminglog: {
    backgroundColor: "white",
    borderRadius: "16px",
    boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.1)",
    padding: "12px",
  },
  clockiconcolor: {
    padding: "3px",
    color: "#8A8FA9",
    position: "relative",
    fontSize: "16px",
  },
  backgroundColorstreamdateTime: {
    backgroundColor: "#8A8FA933",
    borderRadius: "8px",
    marginBottom: "5px",
    width: "fit-content",
    color: "#8A8FA9",
    padding: "5px 5px 2px 5px",
    fontSize: "12px",
    display: "flex",
    alignItems: 'center'
  },
  country: {
    backgroundColor: "#007AFF1A",
    color: " rgba(0, 122, 255, 0.8)",
    paddingLeft: "5px",
    borderRadius: "10px",
    width: "fit-content",
    marginBottom: "5px",
    padding: "2px 5px 2px 5px",
    fontSize: "14px",
    marginRight: "5px",
  },
  language: {
    backgroundColor: "#FDBD2F33",
    color: " rgba(253, 189, 47, 1)",
    width: "fit-content",
    borderRadius: "10px",
    marginBottom: "5px",
    padding: "2px 5px 2px 5px",
    marginRight: "5px",
    fontSize: "14px",
  },
  startup: {
    backgroundColor: "#28CD411A",
    color: "rgba(40, 205, 65, 1)",
    width: "fit-content",
    borderRadius: "10px",
    marginBottom: "5px",
    padding: "2px 5px 2px 5px",
    fontSize: "14px",
  },
  quation: {
    color: "black",
    fontSize: "16px",
    marginBottom: "5px",
    fontFamily: "Helvetica",
    lineHeight: "20px",
    display: "flex",
    fontWeight: "700",
  },
  truncatedText: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  streamers: {
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
  },
  mainsteamer: {
    fontSize: "14px",
    color: "black",
  },
  shareIcon: {
    cursor: "pointer",
  },
}));
