import React, { useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import Chip from "@material-ui/core/Chip";
import { FormattedTime } from "react-player-controls";
import ReactPlayer from "react-player";
import Slider from "rc-slider";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import { ICalendar } from "datebook";
import moment from "moment-timezone";
import {
  Grid,
  Box,
  Avatar,
  Typography,
  Container,
  IconButton,
  Link,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import axios from "axios";
import Logo from "../../assets/images/steev-logo.png";
import Share from "../../assets/images/share.png";
import Copy from "../../assets/images/copy.png";
import Calender from "../../assets/images/calender.png";
import Google from "../../assets/images/Google.png";
import AppStore from "../../assets/images/App-store.png";
import PageNotFound from '../notFound/index'
import { CLOUD_FRONT_DEV_URL, API, SOCIAL_LINKS } from '../../config'
import { constants, EMAILS } from "../../constants/event";
import { useStyles } from "./style";
import { EventDialog } from "../../Modals";

function Index({ match, history }) {
  const classes = useStyles();

  const [state, setState] = useState({
    loading: true,
    hosts: [],
    data: null,
    shows: [],
    date: null,
  });
  const [url, setUrl] = useState(null);

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(EMAILS[1]);
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [viewers, setViewers] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progressObj, setProgressObj] = useState({
    playedSeconds: 0,
    loadedSeconds: 0,
  });

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const generateIcs = () => {
    const config = {
      title: state.data.title,
      description: state.data.description,
      start: new Date(state.data.scheduleDate),
    };

    const icalendar = new ICalendar(config);
    icalendar.download("event.ics");
  };

  const mytimeZone = () => {
    let tzone = moment.tz.guess();
    let dateSplit = state.data.scheduleDate.split("T");
    const ac = moment(dateSplit[0] + state.data.scheduleTime).format(
      "YYYY-MM-DD HH:mm"
    );
    const tzConv = moment.tz(ac, state.data.timeZone);
    const convertedDt = tzConv
      .tz(
        typeof tzone === "number"
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : tzone
      )
      .format("MMM DD, YYYY hh:mmA zz"); // mytimeZone  is my zone
    return convertedDt;
  };

  const updateViewers = () => {
    axios
      .post(`${API}/viewers/update`, {
        hostId: match.params.id
      })
      .then(async (response) => {
        if (response && response.status === 200) {
          if (response.data.code === 200 && response.data.data) {
            setViewers(response.data.data)
          }
        }
      }).catch((err) => {
        console.log('An error in occured in updating viewers', err)
      })
  }

  useEffect(() => {
    let two = `${API}/random-stream`;
    const hosts = [];
    let eventData = false;

    if (match.params.id) {

      axios
        .get(`${API}/host/${match.params.id}`)
        .then(async (response) => {
          if (response?.data) {
            eventData = response?.data?.data;
            //Add a State 
            if (response?.data?.data?.tags) {
              return await axios.post(two, { tags: response?.data?.data?.tags });
            }
            else {
              return false;
            }



          } else {
            return false;
          }
        }).then((response) => {


          if (response && response.data) {



            let filter = [];

            if (response.data.data) {
              filter = response.data.data;
              try {
                if (filter.length > 3) {
                  const max = filter.length - (filter.length > 3 ? 3 : 0);
                  const random = getRandomInt(max);
                  filter = filter.slice(random, random + 3);

                }


              } catch (err) {
                filter = filter.slice(0, (filter.length > 3 ? 3 : filter.length));
              }

              if (eventData.approvedCoHost) {
                eventData.approvedCoHost.forEach((coHost) => {
                  hosts.push({
                    type: "CO-HOST",
                    name: coHost.name,
                    profilePic: coHost.profilePic,
                    username: coHost.username,
                  });
                });
              }
              setState((oldState) => ({
                ...oldState,
                data: eventData,
                shows: filter,
                hosts: hosts,
                loading: false,
                time: '',
              }));
              setViewers(eventData.viewers.length)
              if (eventData && eventData.category === "offline") {

                if (eventData.link) {
                  setUrl(eventData.link)
                }
                if (eventData.records && eventData.records.length > 0) {
                  setUrl(CLOUD_FRONT_DEV_URL + eventData.records[0].fileList[0].fileName)
                }
              }

              if (eventData && eventData.category === "online") {
                if (eventData.records.length > 0) {
                  setUrl(CLOUD_FRONT_DEV_URL + eventData.records[0].fileList[0].fileName)
                }
              }
              if (eventData.userId) {
                hosts.push({
                  type: "HOST",
                  name: eventData.userId.name,
                  profilePic: eventData.userId.profilePic,
                  username: eventData.userId.username,
                });
              }


            }

          } else {
            console.log('page not found')
          }
          delay()
        }).catch((errors) => {
          delay()
          console.log('Errors ', errors)
          // react on errors.
        });

    } else {
      delay()
    }


  }, [match.params.id]);

  const displayTags = (tags, wd) => {
    return (
      <Grid container spacing={3}>
        {tags.map((item, index) => {
          let bgColor = "rgba(0, 122, 255, 0.1)";

          if (index === 1) {
            bgColor = "rgba(253, 189, 47, 0.2)";
          }

          if (index === 2) {
            bgColor = "rgba(40, 205, 65, 0.1)";
          }
          return (
            <Chip
              className={"tag-d tag-d-" + index}
              style={{
                marginLeft: 5,
                minWidth: wd,
                background: bgColor,
              }}
              label={item}
              key={`${item}-${index}`}
            />
          );
        })}
      </Grid>
    );
  };

  const displayShows = (data) => {
    return (
      <a href={data.firelink} key={data.title} style={{ textDecoration: 'none' }}>
        <Grid
          style={{
            width: 270,
            padding: 25,
            background: "#fff",
            borderRadius: 8,
            marginBottom: 20,
          }}
        >
          <div style={{ marginTop: 10 }}>{displayTags(data.tags, 80)}</div>


          <Typography component="div">
            <Box
              py={1}
              mt={2}
              fontWeight="700"
              fontFamily="Helvetica"
              fontSize="22px"
              lineHeight={1.2}
              color="#1C1C1E"
              cursor="pointer"
            >
              {data.title}
            </Box>
          </Typography>
          <Typography component="div">
            <Box
              fontFamily="Helvetica"
              fontSize="16px"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
              }}
              color="rgba(28, 28, 30, 0.8)"
            >

              {data.description}
            </Box>
          </Typography>
          <Box style={{ display: "flex", marginTop: 10 }}>
            <img src="/Mic.png" alt="mic-img" style={{ height: 20 }} />
            <Typography component="div">
              <Box
                fontFamily="Helvetica"
                fontWeight="500"
                fontSize="16px"
                lineHeight="20px"
                color="#000"
              >
                {data.userId.name}
              </Box>
            </Typography>
          </Box>
        </Grid>
      </a>
    );
  };


  function delay() {
    setTimeout(() => {
      setLoading(false)
    }, 100);
  }

  if (state.data === null && loading === false) {
    return <PageNotFound />
  }
  return (
    <div className={classes.root}>
      {loading ? (
        <Box
          height="100vh"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Logo} alt="logo" height="auto" width="170.81px" />
        </Box>
      ) : (
        <Container fixed>
          <Grid container justifyContent="center" spacing={2}>
            <Grid
              item
              style={{
                padding: 20,
                width: 270,
                height: 460,
                marginRight: 12,
              }}
              className="side-left-1"
            >
              <Typography component="div">
                <Box
                  textAlign="left"
                  pt={1}
                  fontFamily="Helvetica"
                  fontWeight="700"
                  lineHeight="35px"
                  fontSize="22px"
                  color="#1C1C1E"
                >
                  {constants.EMBARK_TITLE}
                </Box>
              </Typography>

              <Typography component="div">
                <Box
                  textAlign="left"
                  pt={1}
                  mt={1}
                  fontFamily="Helvetica"
                  fontWeight="400"
                  lineHeight="20px"
                  fontSize="14px"
                  color="#828282"
                >
                  {constants.INSTALL_NOW}
                </Box>
              </Typography>

              <Box pt={2} display="block" mt={1} justifyContent="center">
                <Link
                  href={SOCIAL_LINKS.GOOGLE_PLAY_LINK}
                  target="_blank"
                >
                  <img
                    className={classes.mediaImgfirst}
                    src={Google}
                    alt="Google"
                    height="auto"
                    width="200px"
                  />
                </Link>
                <Link
                  href={SOCIAL_LINKS.APPLE_LINK}
                  target="_blank"
                >
                  <img
                    style={{ marginTop: 20 }}
                    className={classes.mediaImg}
                    src={AppStore}
                    alt="AppStore"
                    height="auto"
                    width="200px"
                  />
                </Link>
              </Box>
            </Grid>
            <Grid item style={{ width: 500, marginRight: -10 }}>
              <Box className={classes.medialogo} py={5} textAlign="center">
                <img src={Logo} alt="logo" height="auto" width="170.81px" />
              </Box>

              <Box className={classes.backgroundcolor}>
                <Box className={classes.clockbg}>
                  <AccessTimeIcon className={classes.clockiconcolor} />
                  <span className={classes.datetext}>{mytimeZone()}</span>
                </Box>
                {state.data.tags.length && (
                  <Box mt={3}>{displayTags(state.data.tags, 100)}</Box>
                )}
                <Typography component="div">
                  <Box
                    py={2}
                    mt={1}
                    fontWeight="700"
                    fontFamily="Helvetica"
                    lineHeight="30px"
                    fontSize="22px"
                    color="#1C1C1E"
                  >
                    {state.data.title}
                  </Box>
                </Typography>
                <Typography component="div">
                  <Box
                    fontFamily="Helvetica"
                    lineHeight="22px"
                    fontSize="16px"
                    color="rgba(28, 28, 30, 0.8)"
                  >
                    {state.data.description}
                  </Box>
                </Typography>

                <Box py={2}>
                  <Grid container spacing={3}>
                    {state.hosts.map((value, i) => {
                      const spt = value.name.split(" ");
                      let avtarName = "";
                      spt.forEach((item) => {
                        if (
                          item !== "" &&
                          item !== null &&
                          item !== undefined &&
                          avtarName.length < 2
                        ) {
                          if (avtarName === "") {
                            avtarName = item.charAt(0);
                          } else {
                            avtarName += item.charAt(0);
                          }
                        }
                      });

                      return (
                        <Grid
                          item
                          key={value.name}
                          lg={3}
                          md={3}
                          sm={4}
                          xs={4}
                          className={classes.cursor}
                          onClick={() => history.push(`/${value.username}`)}
                        >
                          {value.profilePic !== undefined ? (
                            <Avatar
                              alt={value.name}
                              src={value.profilePic}
                              className={classes.large}
                            />
                          ) : (
                            <Avatar
                              alt={value.name}
                              style={{ background: "gray" }}
                              src={value.profilePic}
                              className={classes.large}
                            >
                              {" "}
                              {avtarName}{" "}
                            </Avatar>
                          )}

                          <Typography component="div">
                            <Box
                              textAlign="center"
                              pt={1}
                              fontFamily="Helvetica"
                              fontWeight="700"
                              lineHeight="20px"
                              fontSize="10px"
                              color="#8A8FA9"
                            >
                              {value.type}
                            </Box>
                          </Typography>
                          <Typography component="div">
                            <Box
                              className={classes.mediatext}
                              textAlign="center"
                              fontFamily="Helvetica"
                              fontWeight="400"
                              lineHeight="20px"
                              fontSize="14px"
                              color="#000000"
                            >
                              {value.name}
                            </Box>
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Grid
                    display="flex"
                    style={{ marginTop: 20, display: "flex" }}
                  >
                    <Grid item md={6} style={{ width: 300 }}>
                      <Typography component="div">
                        <Box
                          className={classes.mediatext}
                          textAlign="left"
                          fontFamily="Helvetica"
                          fontWeight="400"
                          lineHeight="20px"
                          fontSize="14px"
                          color="#8A8FA9"
                        >
                          {constants.BROUGHT_BY}
                        </Box>
                      </Typography>
                      <img
                        style={{ marginTop: 10, width: 150 }}
                        src={"/kooberi.png"}
                        alt="kooberi-img"
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img style={{ marginTop: 0 }} src={"/micOptions.png"} alt="headphone-img" />
                      <Typography
                        component="div"
                        style={{
                          marginLeft: 20,
                        }}
                      >
                        <Box
                          className={classes.mediatext}
                          textAlign="center"
                          fontFamily="Helvetica"
                          fontWeight="700"
                          lineHeight="20px"
                          fontSize="16px"
                          color="#000"
                        >
                          {viewers}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box py={2} textAlign="center">
                <Grid container>
                  <Grid item xs={4}>
                    <Box textAlign="right">
                      <IconButton
                        className={classes.borderbg}
                        aria-label="delete"
                        onClick={handleClickOpen}
                      >
                        <img
                          src={Share}
                          alt="Share"
                          height="auto"
                          width="20px"
                        />
                      </IconButton>
                      <Typography component="div">
                        <Box
                          pt={1}
                          pr={1}
                          fontFamily="Helvetica"
                          fontWeight="400"
                          lineHeight="18px"
                          fontSize="12px"
                          color="#000000"
                        >
                          {constants.SHARE}
                        </Box>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <IconButton
                      className={classes.borderbg}
                      aria-label="delete"
                      onClick={() =>
                        navigator.clipboard.writeText(window.location.href)
                      }
                    >
                      <img
                        src={Copy}
                        alt="Share"
                        height="auto"
                        width="20px"
                      />
                    </IconButton>
                    <Typography component="div">
                      <Box
                        pt={1}
                        fontFamily="Helvetica"
                        fontWeight="400"
                        lineHeight="18px"
                        fontSize="12px"
                        color="#000000"
                      >
                        {constants.COPY_LINK}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box pl={2} textAlign="left">
                      <IconButton
                        className={classes.borderbg}
                        aria-label="delete"
                        onClick={generateIcs}
                      >
                        <img
                          src={Calender}
                          alt="Share"
                          height="auto"
                          width="20px"
                        />
                      </IconButton>
                    </Box>
                    <Typography component="div">
                      <Box
                        textAlign="left"
                        pt={1}
                        fontFamily="Helvetica"
                        fontWeight="400"
                        lineHeight="18px"
                        fontSize="12px"
                        color="#000000"
                      >
                        {constants.ADD_CALENDER}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              className="slide-right-1"
              style={{ width: 270, marginTop: 120, padding: 20 }}
            >
              {state.shows.length > 0 &&
                state.shows.map((item) => {
                  return displayShows(item);
                })}
            </Grid>
          </Grid>
        </Container>
      )}
      <EventDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
      <Box py={6} />
      {state.data &&

        <div>
          {url && (
            <div
              className="footer-stripe"
              style={{ height: isPlaying ? "100px" : "80px" }}
            >
              <div className="player-wrapper">
                <ReactPlayer
                  ref={playerRef}
                  progressInterval={500}
                  volume={1}
                  playing={isPlaying}
                  muted={false}
                  url={url}
                  className="player"
                  width="100%"
                  height="100%"
                  onEnded={() => {
                    console.log('Playing ended')
                  }}
                  onDuration={(d) => setDuration(d)}
                  onProgress={(o) => {
                    setProgressObj(o);
                    if (o.played === 1) {
                      updateViewers()
                      setProgressObj({
                        playedSeconds: 0,
                        loadedSeconds: 0,
                      })

                      setIsPlaying(true)
                      playerRef.current.seekTo(0, "seconds");
                    }
                  }}
                />

                <div className="group">
                  <button
                    className={state.data.category === "offline" ? "group play-btn" : "group1 play-btn "}
                    style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                    title={!isPlaying ? "Click to play" : 'Click to pause'}
                    onClick={() => {
                      if (!isPlaying) {
                        updateViewers()
                      }
                      setIsPlaying(!isPlaying)
                    }}
                  >
                    {isPlaying ? (
                      <PauseCircleOutlineIcon
                        style={{ height: "2em", width: "150px" }}
                      />
                    ) : (
                      <PlayCircleOutlineIcon
                        style={{ height: "2em", width: "150px" }}
                      />
                    )}
                  </button>
                </div>
              </div>
              {isPlaying && (
                <div className={state.data.category === "offline" ? "slider" : "slider mt-slider"} >
                  <Slider
                    max={Math.ceil(progressObj.loadedSeconds)}
                    defaultValue={0}
                    value={Math.ceil(progressObj.playedSeconds)}
                    onChange={(v) => {
                      playerRef.current.seekTo(parseFloat(v), "seconds");
                    }}
                  />

                  <span className="duration">
                    {isPlaying ? (
                      <FormattedTime
                        numSeconds={
                          Math.ceil(duration) -
                          Math.ceil(progressObj.playedSeconds)
                        }
                      />
                    ) : (
                      <FormattedTime numSeconds={duration} />
                    )}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      }

    </div>
  );
}

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default Index;