export const CLOUD_FRONT_DEV_URL = "https://d3718hvbdh2rql.cloudfront.net/";
export const API = 'https://api.steevnetworks.com/api/v1';

// url link
export const STEEV_URL = "https://steevnetworks.com";

// Social links
export const SOCIAL_LINKS = {
  APPLE_LINK:
    "https://apps.apple.com/us/app/steev/id1561919092#?platform=iphone",
  GOOGLE_PLAY_LINK:
    "https://play.google.com/store/apps/details?id=com.steev.live&hl=en_IN&gl=US",
};
