import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
  },
  backgroundcolor: {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "20px",
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    margin: "auto",
    "& img": {
      position: "relative",
      top: 0,
      left: 0,
    },
  },
  clockbg: {
    backgroundColor: "rgba(138, 143, 169, 0.2)",
    borderRadius: "8px",
    padding: "3px 10px 3px 10px",
    display: "inline-block",
    height: "24px",
  },
  clockiconcolor: {
    padding: "3px",
    color: "#8A8FA9",
    position: "relative",
    fontSize: "16px",
  },
  datetext: {
    fontSize: "12px",
    position: "relative",
    top: "-6px",
    color: "#8A8FA9",
  },
  borderbg: {
    backgroundColor: "#E4EFF7",
    borderRadius: "18px",
    height: 48,
    width: 48,
  },
  mediatext: {
    [theme.breakpoints.only("md")]: {
      fontSize: "12px",
    },
  },
  medialogo: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  mediaImgfirst: {
    marginRight: 16,
    [theme.breakpoints.down("xs")]: {
      width: "170px",
    },
  },
  mediaImg: {
    [theme.breakpoints.down("xs")]: {
      width: "157px",
    },
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  Dialog: {
    "& .MuiPaper-root": {
      width: 320,
    },
  },
  social: {
    width: "100%",
  },
  cursor: {
    cursor: "pointer",
    justifyContent: "center",
    display: "grid",
  },
}));
