import PropTypes from 'prop-types';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import RedditIcon from "@material-ui/icons/Reddit";
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
import { Avatar } from "@material-ui/core/";
import { useStyles } from "../../views/profile/style";
import { STEEV_URL } from '../../config';

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const urlRedirect = `${STEEV_URL}/event/${open._id}/online`;
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      className={classes.Dialog}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Share</DialogTitle>
      <List style={{ display: "inline", width: 300 }}>
        <FacebookShareButton className={classes.social} url={urlRedirect}>
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <FacebookIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Facebook"} />
          </ListItem>
        </FacebookShareButton>
        <TwitterShareButton className={classes.social} url={urlRedirect}>
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <TwitterIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Twitter"} />
          </ListItem>
        </TwitterShareButton>
        <LinkedinShareButton className={classes.social} url={urlRedirect}>
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <LinkedInIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Linkendin"} />
          </ListItem>
        </LinkedinShareButton>
        <RedditShareButton className={classes.social} url={urlRedirect}>
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <RedditIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Reddit"} />
          </ListItem>
        </RedditShareButton>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
  open: PropTypes.bool,
};

export default SimpleDialog;