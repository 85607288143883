import { makeStyles, withStyles } from "@material-ui/core/styles";
import { InputBase } from "@material-ui/core/";

export const useStyles = makeStyles((theme) => ({
  root: {
    border: "none !important",
    backgroundColor: "#F2F2F2",
    "& .MuiInput-underline::before": {
      border: "none !important",
      borderBottom: "none !important",
    },
    "& :hover": {
      border: "none !important",
    },
    "& .MuiInput-underline::after": {
      transform: "none",
      border: "none !important",
      borderBottom: "none !important",
    },
  },
  mt: {
    marginTop: "30px !important",
    "& .MuiInput-underline::before": {
      border: 0,
    },
    "& :hover": {
      border: 0,
    },
    "& .MuiInput-underline": {
      border: 0,
    },
  },
  backgroundcolor: {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "30px",
    marginBottom: "50px",
    minHeight: "850px",
    boxShadow: "4px 2px 16px rgba(0, 0, 0, 0.1)",
  },

  submitButtton: {
    width: "100%",
    height: 45,
    marginTop: 30,
    color: "#fff",
    padding: 0,
    borderRadius: 14,
    fontFamily: "Helvetica",
    fontSize: "16px",
    background: "#007AFF",
    "& .MuiButton-label": {
      height: 45,
      borderRadius: 14,
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: 600,
      textTransform: "capitalize",
    },
    "&:hover": {
      background: "#007AFF",
      color: "#fff",
      padding: 0,
    },
  },
  user: {
    fontFamily: "Helvetica",
    fontSize: "12px",
    color: "#1C1C1E",
    fontWeight: 700,
    marginTop: 10,
    marginLeft: 10,
  },
  texttitle: {
    color: "#1C1C1E",
    lineHeight: "20px",
    fontWeight: 700,
    marginTop: 30,
    fontFamily: "Helvetica",
    fontSize: "26px",
    textAlign: "center",
  },
  label: {
    lineHeight: "20px",
    fontWeight: 700,
    color: "#8A8FA9",
    width: "100%",
    fontStyle: "normal",
    letterSpacing: "2px",
    fontFamily: "Helvetica",
    fontSize: "18px",
  },
  backgroundcolortitle: {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "30px",
  },
  margin: {
    width: "100%",
    marginTop: 30,
    marginBottom: 0,
  },
  icon: {
    color: "#fff",
    fontWeight: 600,
    fontSize: 20,
    marginRight: 10,
  },
  chip: {
    marginLeft: 0,
    "&:hover": {
      background: "#1976d2",
    },
  },
  err: {
    color: "red",
    marginTop: 10,
    marginBottom: 10,
  },
  textField: {
    marginTop: 30,

    fontSize: 16,
    width: "100%",
    borderRadius: 16,
    color: "#1C1C1E",
    padding: "10px 12px",
    fontWeight: 500,
    background: "#8A8FA90D",
    "& .MuiInput-underline::after": {
      transform: "none",
      border: 0,
    },
    "& .MuiInput-underline::before": {
      transform: "none",
      border: 0,
    },
    "& :hover": {
      border: 0,
    },
    "& MuiInputBase-formControl::before": {
      border: "none",
    },
    "& MuiInputBase-formControl::after": {
      border: "none",
    },
    "& MuiInputBase-formControl:hover": {
      border: "none",
    },
  },
  maintag: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: 10,
    marginTop: 10,
    gap: '8px'
  },
}));

export const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#1C1C1E !important",
    },
  },
  input: {
    position: "relative",
    border: 0,
    fontSize: 16,
    width: "100%",
    borderRadius: 16,
    color: "#1C1C1E",
    padding: "15px 12px",
    fontWeight: 500,
    background: "#8A8FA90D",
    fontFamily: "Helvetica",
    transform: "none",
    "& .MuiInput-underline::before": {
      border: "none !important",
      borderBottom: "none !important",
      transform: "none",
    },
    "& :hover": {
      border: "none !important",
      transform: "none",
    },
    "& .MuiInput-underline::after": {
      transform: "none",
      border: "none !important",
    },
  },
}))(InputBase);
